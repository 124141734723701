@import url(https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700);
@import url(https://fonts.googleapis.com/css?family=Quicksand&display=swap);
@import url(https://fonts.googleapis.com/css?family=Lato&display=swap);
@import url(https://fonts.googleapis.com/css?family=Varela+Round&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
  font-family: 'Varela Round', sans-serif;
}

.rounded-border {
  border-radius: 20px;
}

.container-fluid {
  padding-left: 0;
  padding-right: 0;
}

a,
a:hover,
a:focus {
  color: inherit;
  text-decoration: none;
  transition: all 0.3s;
}

.navbar {
  padding: 15px 10px;
  background: #fff;
  border: none;
  border-radius: 0;
  margin-bottom: 40px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
}

.navbar-btn {
  box-shadow: none;
  outline: none !important;
  border: none;
}

.line {
  width: 100%;
  height: 1px;
  border-bottom: 1px dashed #ddd;
  margin: 40px 0;
}

/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */

.App {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  -webkit-align-items: stretch;
          align-items: stretch;
  -webkit-perspective: 1500px;
          perspective: 1500px;
}


#sidebar {
  min-width: 250px;
  max-width: 250px;
  background: #17465a;
  color: #fff;
  transition: all 0.6s;
  -webkit-transform-origin: bottom left;
          transform-origin: bottom left;
}

#sidebar.active {
  margin-left: -250px;
}

#sidebar .sidebar-header {
  padding: 20px;
  background: #1e5b75;
}

#sidebar ul.components {
  padding: 20px 0;
  border-bottom: 1px solid #1e5b75;
}

#sidebar ul {
  color: #fff;
  padding: 10px;
}

#sidebar ul li a {
  padding: 10px;
  font-size: 1.1em;
  display: block;
}

#sidebar ul li a:hover {
  color: #17465a;
  background: #fff;
}



a[data-toggle="collapse"] {
  position: relative;
}

.dropdown-toggle::after {
  display: block;
  position: absolute;
  top: 50%;
  right: 20px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

ul ul a {
  font-size: 0.9em !important;
  padding-left: 30px !important;
  background: #6d7fcc;
}




/* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */
#content {
  width: 100%;
  padding: 20px;
  min-height: 100vh;
  transition: all 0.3s;
}

#sidebarCollapse {
  width: 40px;
  height: 40px;
  background: #f5f5f5;
  cursor: pointer;
}

#sidebarCollapse span {
  width: 80%;
  height: 2px;
  margin: 0 auto;
  display: block;
  background: #555;
  transition: all 0.8s;
  transition-delay: 0.2s;
}

#sidebarCollapse span:first-of-type {
  -webkit-transform: rotate(45deg) translate(2px, 2px);
          transform: rotate(45deg) translate(2px, 2px);
}

#sidebarCollapse span:nth-of-type(2) {
  opacity: 0;
}

#sidebarCollapse span:last-of-type {
  -webkit-transform: rotate(-45deg) translate(1px, -1px);
          transform: rotate(-45deg) translate(1px, -1px);
}


#sidebarCollapse.active span {
  -webkit-transform: none;
          transform: none;
  opacity: 1;
  margin: 5px auto;
}

.timeline {
  text-align: right;
  color: #17465a;
}

/* ---------------------------------------------------
    MEDIAQUERIES
----------------------------------------------------- */
@media (max-width: 768px) {
  .timeline {
    text-align: left;

  }

  #sidebar {
    margin-left: -250px;
  }

  #sidebar.active {
    margin-left: 0;
    -webkit-transform: none;
            transform: none;
  }

  #sidebarCollapse span:first-of-type,
  #sidebarCollapse span:nth-of-type(2),
  #sidebarCollapse span:last-of-type {
    -webkit-transform: none;
            transform: none;
    opacity: 1;
    margin: 5px auto;
  }

  #sidebarCollapse.active span {
    margin: 0 auto;
  }

  #sidebarCollapse.active span:first-of-type {
    -webkit-transform: rotate(45deg) translate(2px, 2px);
            transform: rotate(45deg) translate(2px, 2px);
  }

  #sidebarCollapse.active span:nth-of-type(2) {
    opacity: 0;
  }

  #sidebarCollapse.active span:last-of-type {
    -webkit-transform: rotate(-45deg) translate(1px, -1px);
            transform: rotate(-45deg) translate(1px, -1px);
  }

}


/* Body background */
.content {
  margin-top: 5rem;
  margin-bottom: 5rem;
}

.body {
  background-color: #1e5b75;
}

/* END body background */


.intro-stage {
  font-size: 4rem;
  height: 33rem;
}


/* START Profile */
i {
  color: #1e5b75;
  margin: 0.5rem 0;
}

.list {
  padding-left: 0;

}

/* END Profile */

/* START Progress bar */
.progress-bar {
  background-color: #1e5b75;
  width: 0;
  -webkit-animation: progress 1.5s ease-in-out forwards;
          animation: progress 1.5s ease-in-out forwards;
}

.title {
  opacity: 0;
  -webkit-animation: show 0.35s forwards ease-in-out 0.5s;
          animation: show 0.35s forwards ease-in-out 0.5s;
}


/* START Animation */
.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
          animation-name: fadeInLeft;
}

.fadeInRight {
  -webkit-animation-name: fadeInRight;
          animation-name: fadeInRight;
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
          animation-name: fadeInUp;
}

.animated {
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}

.App .animate-box {
  opacity: 0;
  display: block;
}

div {
  display: block;
}


/* END Animation */
@-webkit-keyframes progress {
  from {
    width: 0;
  }

  to {
    width: 100%;
  }
}
@keyframes progress {
  from {
    width: 0;
  }

  to {
    width: 100%;
  }
}

@-webkit-keyframes show {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes show {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 40px, 0);
            transform: translate3d(0, 40px, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
            transform: none;
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 40px, 0);
            transform: translate3d(0, 40px, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
            transform: none;
  }
}

@-webkit-keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-50px, 0, 0);
            transform: translate3d(-50px, 0, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
            transform: none;
  }
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-50px, 0, 0);
            transform: translate3d(-50px, 0, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
            transform: none;
  }
}

@-webkit-keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(50px, 0, 0);
            transform: translate3d(50px, 0, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
            transform: none;
  }
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(50px, 0, 0);
            transform: translate3d(50px, 0, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
            transform: none;
  }
}

/* END Progress bar */
